import React from 'react';
import {createRoot} from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
    createHashRouter,
    RouterProvider,
} from "react-router-dom";
import {LandingPage} from "./pages/landingPage";
import {ProjectsPage} from "./pages/projectsPage";
import {paths} from "./constants";
import 'bootstrap/dist/css/bootstrap.min.css';
import {AboutPage} from "./pages/aboutPage";
import {ResumePage} from "./pages/resumePage";
import {ValentinesDayPage} from "./pages/valentinesDayPage";

const router = createHashRouter([
    {path: paths.projects, element: <ProjectsPage />},
    {path: paths.about, element: <AboutPage />},
    {path: paths.resume, element: <ResumePage />},
    {path: paths.valentines, element: <ValentinesDayPage />},
    {path: paths.root, element: <LandingPage />}
]);

const root = createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
            integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM"
            crossOrigin="anonymous"
        />
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
